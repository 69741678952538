button {
  font-family: 'Barlow';
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.15em;
  background-color: var(--color-marine-700);
  outline: none;
  border: none;
  color: var(--color-marine-300);
  cursor: pointer;
  transition: all 0.5s ease-out;
  padding: 8px 15px;
  text-transform: uppercase;
  border-radius: 40px;
}

button:hover {
  background-color: var(--color-marine-500);
  color: var(--color-marine-100);
}

button.active {
  background-color: var(--color-white);
  color: var(--color-marine-900);
}

.app-store-button {
  width: 120px;
  height: 40px;
  background-color: transparent !important;
  background-image: url('../icons/app-store-icon.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 0;
}

.play-store-button {
  width: 135px;
  height: 40px;
  background-color: transparent !important;
  background-image: url('../icons/play-store-icon.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 0;
}

.play-store-button.colored {
  background-image: url('../icons/playstore-colored.png');
}
