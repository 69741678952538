#dark-crown-section {
  background-color: var(--color-black-currant);
  width: 100%;
}

#dark-crown-section #dark-crown-banner {
  background-image: url("../img/dark-crown-background.png");
  background-repeat: no-repeat;
  background-size: auto 595px;
  background-position: center top;
  width: 100%;
  height: 595px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

#dark-crown-section #dark-crown-banner .dark-crown-logo-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#dark-crown-section
  #dark-crown-banner
  .dark-crown-logo-wrapper
  .dark-crown-logo {
  width: auto;
  height: 240px;
  margin-right: 800px;
  box-sizing: border-box;
}

#dark-crown-section #dark-crown-banner .buttons-section {
  flex-grow: 1;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-end;
}

#dark-crown-section #dark-crown-banner .dark-crown-text {
  box-sizing: border-box;
  background-image: url("../img/brush_stroke_text_background.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 100% auto;
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 10px 50px;
  text-align: center;
}

#dark-crown-section #dark-crown-banner .dark-crown-text p {
  font-family: "Ubuntu";
  color: var(--color-marine-200);
}

#dark-crown-section #dark-crown-screenshots {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -30px;
  box-sizing: border-box;
  padding-bottom: 30px;
}

#dark-crown-section #dark-crown-screenshots .separator {
  width: 1000px;
  height: 55px;
  background-size: 100% auto;
  max-width: 100%;
}

#dark-crown-section #dark-crown-screenshots .top-separator {
  background-image: url("../img/dark-crown/line_01.png");
  margin-bottom: 10px;
  background-repeat: no-repeat;
}

#dark-crown-section #dark-crown-screenshots .bottom-separator {
  background-image: url("../img/dark-crown/line_02.png");
  margin-top: 20px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

#dark-crown-section #dark-crown-screenshots .screenshots-grid {
  display: flex;
  flex-direction: row;
  width: 970px;
  gap: 15px;
  flex-wrap: wrap;
  max-width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
}

#dark-crown-section
  #dark-crown-screenshots
  .screenshots-grid
  .screenshots-item {
  flex-basis: 300px;
  width: 300px;
  height: 169px;
  max-width: 100%;
  cursor: pointer;
  position: relative;
}

#dark-crown-section
  #dark-crown-screenshots
  .screenshots-grid
  .screenshots-item
  img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
}

#dark-crown-section #dark-crown-screenshots .wishlist-button img {
  width: 200px;
  height: auto;
}

#dark-crown-section
  #dark-crown-screenshots
  .screenshots-grid
  .screenshots-item
  .screenshot-hover-mask {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: rgba(10, 14, 20, 0.6);
  transition: all 0.5s ease-out;
  opacity: 0;
}

#dark-crown-section
  #dark-crown-screenshots
  .screenshots-grid
  .screenshots-item:hover
  .screenshot-hover-mask {
  opacity: 1;
}

#dark-crown-section
  #dark-crown-screenshots
  .screenshots-grid
  .screenshots-item
  .screenshot-hover-mask
  .screenshot-expand-button {
  background-image: url("../icons/expand.svg");
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: var(--color-marine-900);
  width: 56px;
  height: 56px;
  border-radius: 28px;
}

#dark-crown-section
  #dark-crown-screenshots
  .screenshots-grid
  .screenshots-item
  .screenshot-hover-mask
  .screenshot-expand-label {
  font-family: "Barlow";
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: var(--color-white);
  margin-top: 15px;
}

@media (max-width: 1151px) {
  #dark-crown-section #dark-crown-banner .dark-crown-logo-wrapper {
    align-items: flex-start;
    padding: 0px 20px;
  }

  #dark-crown-section
    #dark-crown-banner
    .dark-crown-logo-wrapper
    .dark-crown-logo {
    margin-right: 0px;
  }
}

@media (max-width: 659px) {
  #dark-crown-section #dark-crown-banner .dark-crown-logo-wrapper {
    align-items: center;
    padding: 0px 20px;
  }

  #dark-crown-section
    #dark-crown-banner
    .dark-crown-logo-wrapper
    .dark-crown-logo {
    width: 280px;
    height: auto;
    max-width: 100%;
  }

  #dark-crown-section #dark-crown-banner {
    background-image: url("../img/dark-crown-background-mobile.png");
    background-size: auto 100%;
    background-position: center top;
    height: 1195px;
    max-height: 95vh;
  }

  #dark-crown-section #dark-crown-banner .dark-crown-text {
    background-image: url("../img/brush_stroke_text_background_mobile.png");
    margin-top: 30px;
  }
}
