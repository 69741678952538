#screenshot-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

#screenshot-modal .screenshot-modal-content {
  width: 100%;
  height: 100%;
  max-width: 1070px;
  max-height: 600px;
  position: relative;
}

#screenshot-modal .screenshot-modal-content .screenshot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

#screenshot-modal .screenshot-modal-content .screenshot img {
  width: 100%;
  height: auto;
}

#screenshot-modal .screenshot-modal-content .close-modal {
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 9999;
}

#screenshot-modal .screenshot-modal-content .screenshot-modal-buttons {
  position: absolute;
  z-index: 9998;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

@media only screen and (max-width: 1151px) {
  #screenshot-modal .screenshot-modal-content {
    max-width: 700px;
    max-height: 392px;
  }
}

@media only screen and (max-width: 959px) {
  #screenshot-modal .screenshot-modal-content {
    max-width: 100%;
    max-height: 100%;
  }
}
