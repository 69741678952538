@import url('./root.css');

#navbar {
  height: 72px;
  width: 100%;
  background-color: var(--color-marine-900);
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
}

#navbar.fixed-navbar {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
}

#navbar .menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  height: 36px;
}

#navbar .menu .navbar-logo {
  padding-top: 2px;
  width: auto;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#navbar .menu .navbar-logo img {
  height: 32px;
  margin-left: -150px;
}

#navbar .menu .menu-open-close-button {
  display: none;
}

#navbar .menu .nav {
  display: flex;
  flex-direction: row;
  height: 36px;
  align-items: center;
  column-gap: 20px;
}

#navbar .menu .nav .nav-item {
  font-family: 'Barlow';
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.15em;
  color: var(--color-white);
  text-transform: uppercase;
  cursor: pointer;
  background: none;
}

.hiring-advertisement {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  background-color: var(--color-blue);
  color: var(--color-white);
  font-family: 'Barlow';
  font-weight: 800;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.15em;
  display: none;
}

@media (max-width: 1151px) {
  #navbar .language-buttons {
    display: none;
  }

  #navbar .menu .navbar-logo {
    justify-content: flex-start;
    flex-grow: 0;
  }

  #navbar .menu .navbar-logo img {
    margin-left: 20px;
    height: 28px;
  }

  #navbar .menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 20px;
    flex-grow: 1;
    height: 36px;
  }

  #navbar .menu .nav {
    display: none;
  }

  #navbar .menu .menu-open-close-button {
    display: block;
    background-color: transparent;
    color: var(--color-white);
    background-image: url('../icons/menu-open.svg');
    background-repeat: no-repeat;
    background-size: 24px 16px;
    background-position: center right;
    padding-right: 34px;
    border-radius: 0px;
    transition: none;
  }

  #navbar .menu .menu-open-close-button.active {
    background-image: url('../icons/menu-close.svg');
    background-size: 18px 18px;
    padding-right: 28px;
  }
}

@media (max-width: 420px) {
  #navbar .menu .navbar-logo img {
    margin-left: 0px;
  }
}
