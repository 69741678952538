#projects-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

#projects-modal .projects-modal-dialog {
  width: 100%;
  height: 100%;
  max-width: 810px;
  max-height: 720px;
  border-radius: 12px;
  position: relative;
}

#projects-modal .projects-modal-dialog .close-modal {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 999;
}

#projects-modal .projects-modal-dialog .projects-modal-content {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

#projects-modal .projects-modal-content .projects-modal-images-section {
  width: 50%;
  flex-basis: 50%;
  flex-grow: 0;
  position: relative;
}

#projects-modal .projects-modal-content .projects-modal-images-section .projects-modal-images {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 12px 0px 0px 12px;
}

#projects-modal
  .projects-modal-content
  .projects-modal-images-section
  .projects-modal-images
  .inactive-item {
  transition: all 0.2s ease-out;
  opacity: 0;
  z-index: 1;
}

#projects-modal
  .projects-modal-content
  .projects-modal-images-section
  .projects-modal-images
  .active-item {
  transition: all 0.2s ease-out;
  opacity: 1;
  z-index: 2;
}

#projects-modal .projects-modal-content .projects-modal-images-section .projects-modal-images img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  border-radius: 12px 0px 0px 12px;
}

#projects-modal
  .projects-modal-content
  .projects-modal-images-section
  .projects-modal-images-button {
  position: absolute;
  right: 15px;
  bottom: 15px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

#projects-modal
  .projects-modal-content
  .projects-modal-images-section
  .projects-modal-images-indicator {
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 2;
  background-color: var(--color-marine-900);
  padding: 10px 20px;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: var(--color-white);
  border-radius: 0px 8px 0px 8px;
}

#projects-modal .projects-modal-content .projects-modal-details {
  width: 50%;
  flex-basis: 50%;
  flex-grow: 0;
  background-color: var(--color-white);
  padding: 50px 50px;
  border-radius: 0px 12px 12px 0px;
  background-image: url('../img/hyperdive-logo-background.png');
  background-repeat: no-repeat;
  background-position: bottom right;
}

#projects-modal .projects-modal-content .projects-modal-details .projects-modal-title {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 275;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: var(--color-marine-800);
}

#projects-modal .projects-modal-content .projects-modal-details .projects-modal-play-store {
  margin-top: 30px;
}

@media only screen and (max-width: 875px) {
  #projects-modal .projects-modal-dialog {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 12px;
    position: relative;
  }

  #projects-modal .projects-modal-content .projects-modal-images-section {
    width: 100%;
    height: 100%;
    flex-basis: 100%;
    flex-grow: 0;
    position: relative;
  }

  #projects-modal .projects-modal-content .projects-modal-images-section .projects-modal-item {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  #projects-modal .projects-modal-content .projects-modal-images-section .projects-modal-details {
    width: 100%;
    height: 100%;
    flex-basis: 100%;
    flex-grow: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 0;
  }

  #projects-modal
    .projects-modal-content
    .projects-modal-images-section
    .projects-modal-images
    img {
    border-radius: 0;
  }

  #projects-modal
    .projects-modal-content
    .projects-modal-images-section
    .projects-modal-images-indicator {
    border-radius: 0px 8px 0px 0px;
  }
}
