#navbar-full-screen {
  position: fixed;
  top: 72px;
  left: 0px;
  width: 100%;
  height: calc(100% - 72px);
  background-color: var(--color-marine-900);
  z-index: 999;
  border-top: 1px solid var(--color-marine-700);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

#navbar-full-screen .navbar-full-screen-itens {
  flex-grow: 1;
}

#navbar-full-screen .navbar-full-screen-itens .nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 45px;
  padding: 45px 20px;
}

#navbar-full-screen .navbar-full-screen-itens .nav button {
  background-color: transparent;
  color: var(--color-white);
  padding: 0;
}

#navbar-full-screen .navbar-full-screen-buttons {
  height: 80x;
  flex-basis: 80px;
  border-top: 1px solid var(--color-marine-700);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-grow: 0;
  padding: 0px 20px;
}

#navbar-full-screen .navbar-full-screen-buttons .social-media-buttons {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
}

#navbar-full-screen .navbar-full-screen-buttons .language-section .language-change-button {
  padding: 0 !important;
}
