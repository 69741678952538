#projects-section {
  background-color: var(--color-marine-900);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

#projects-section .projects-section-container {
  width: 1129px;
}

#projects-section .games-grid {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  max-height: 1524px;
  width: 100%;
  padding: 50px 20px;
  gap: 16px 16px;
}

#projects-section .title-box {
  cursor: auto !important;
}

#projects-section .title-box.mobile {
  display: none;
}

#projects-section .title-box h2 {
  color: var(--color-white);
  padding: 0;
  margin: 0;
}

#projects-section .title-box p {
  color: var(--color-marine-300);
}

#projects-section .title-box button {
  color: var(--color-white);
  background-color: var(--color-marine-900) !important;
  border: 1px solid var(--color-white);
  padding: 12px 20px;
  padding-right: 45px;
  background-image: url('../icons/external.svg');
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: right 17px center;
  margin-top: 4px;
}

#projects-section .title-box button:hover {
  background-color: var(--color-marine-700) !important;
}

#projects-section .games-grid .game-item-square {
  width: 260px;
  height: 260px;
}

#projects-section .games-grid .game-item-rectangle {
  width: 260px;
  height: 536px;
}

#projects-section .games-grid .game-item {
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  transition: all 0.5s ease-out;
}

#projects-section .games-grid .game-item:hover {
  filter: grayscale(100%);
}

#projects-section .games-grid .game-item:hover img {
  opacity: 0.5;
}

#projects-section .games-grid .game-item img {
  border-radius: 8px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  transition: all 0.5s ease-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#projects-section .games-grid .game-item .game-item-hover-mask {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: rgba(19, 26, 37, 0.2);
  transition: all 0.5s ease-out;
  opacity: 0;
  border-radius: 8px;
}

#projects-section .games-grid .game-item:hover .game-item-hover-mask {
  opacity: 1;
}

#projects-section .games-grid .game-item .game-item-hover-mask .game-item-expand-button {
  background-image: url('../icons/expand.svg');
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: var(--color-marine-900);
  width: 56px;
  height: 56px;
  border-radius: 28px;
}

#projects-section .games-grid .game-item .game-item-hover-mask .game-item-expand-label {
  font-family: 'Barlow';
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: var(--color-white);
  margin-top: 15px;
}

#projects-section .games-grid .game-item .game-item-brand {
  border-radius: 8px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 3;
  background-color: var(--color-marine-900);
  color: var(--color-white);
  padding: 10px 10px 5px 5px;
  font-family: 'Barlow';
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  border-radius: 0px 8px 0px 0px;
}

@media (max-width: 1151px) {
  #projects-section .projects-section-container {
    width: 926px;
  }

  #projects-section .games-grid {
    max-height: 1335px;
    padding: 30px 15px;
    padding-bottom: 0px;
  }

  #projects-section .games-grid .game-item-square {
    width: 212px;
    height: 212px;
  }

  #projects-section .games-grid .game-item-square:nth-of-type(4) {
    width: 212px;
    height: 440px;
  }

  #projects-section .games-grid .game-item-rectangle {
    width: 212px;
    height: 440px;
  }

  #projects-section .title-box.desktop {
    display: none;
  }

  #projects-section .title-box.mobile {
    display: block;
    width: 100%;
    padding: 30px 15px;
    padding-bottom: 0px;
  }

  #projects-section .title-box h2 {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.2em;
  }
}

@media (max-width: 959px) {
  #projects-section .projects-section-container {
    width: 520px;
  }

  #projects-section .games-grid {
    max-height: 950px;
    padding: 35px 0px;
    padding-bottom: 0px;
  }

  #projects-section .games-grid .game-item-square {
    width: 118px;
    height: 148px;
  }

  #projects-section .games-grid .game-item-square:nth-of-type(4) {
    width: 118px;
    height: 312px;
  }

  #projects-section .games-grid .game-item-rectangle {
    width: 118px;
    height: 312px;
  }

  #projects-section .title-box.mobile {
    padding: 30px 0px;
    padding-bottom: 0px;
  }

  #projects-section .games-grid .game-item .game-item-brand {
    padding: 8px 8px 6px 4px;
    font-size: 12px;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
  }

  #projects-section .games-grid .game-item .game-item-hover-mask .game-item-expand-button {
    background-size: 18px 18px;
    width: 42px;
    height: 42px;
  }

  #projects-section .games-grid .game-item .game-item-hover-mask .game-item-expand-label {
    font-size: 12px;
    margin-top: 10px;
  }
}

@media (max-width: 599px) {
  #projects-section .projects-section-container {
    width: 398px;
  }

  #projects-section .games-grid {
    max-height: 1300px;
    padding: 40px 0px;
  }

  #projects-section .games-grid .game-item-square {
    width: 122px;
    height: 148px;
  }

  #projects-section .games-grid .game-item-square:nth-of-type(4) {
    width: 122px;
    height: 312px;
  }

  #projects-section .games-grid .game-item-square:nth-of-type(8) {
    width: 122px;
    height: 312px;
  }

  #projects-section .games-grid .game-item-rectangle {
    width: 122px;
    height: 312px;
  }
}

@media (max-width: 479px) {
  #projects-section .projects-section-container {
    width: 312px;
  }

  #projects-section .games-grid {
    max-height: 1900px;
    padding: 35px 0px;
  }

  #projects-section .games-grid .game-item-square {
    width: 148px;
    height: 148px;
  }

  #projects-section .games-grid .game-item-square:nth-of-type(4) {
    width: 148px;
    height: 148px;
  }

  #projects-section .games-grid .game-item-square:nth-of-type(8) {
    width: 148px;
    height: 312px;
  }

  #projects-section .games-grid .game-item-rectangle {
    width: 148px;
    height: 312px;
  }
}
