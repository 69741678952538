#contact-section {
  background-color: var(--color-marine-900);
  padding: 0px 20px 60px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px 15px;
}

#contact-section #carousel {
  width: 720px;
  height: 352px;
  border-radius: 8px;
}

#contact-section .contact-session {
  background-color: var(--color-marine-700);
  width: 352px;
  height: 352px;
  padding: 30px;
  border-radius: 8px;
  color: var(--color-white);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

#contact-section .contact-session h1 {
  margin-top: 0;
  margin-bottom: 15px;
}

#contact-section .contact-session .contact-item {
  cursor: pointer;
  width: 100%;
  transition: all 0.2s ease-in-out;
}

#contact-section .contact-session .contact-item div {
  display: flex;
  align-items: center;
  padding-left: 5px;
  column-gap: 15px;
}

#contact-section .contact-session .contact-item .hover-text {
  display: none;
}

#contact-section .contact-session .contact-item:hover {
  background-color: var(--color-marine-800);
  border-radius: 8px;
}

#contact-section .contact-session .contact-item:hover .hover-text {
  display: flex;
  padding-left: 15px;
}

#contact-section .contact-session .contact-item:hover .non-hover-text {
  display: none;
}

#contact-section .contact-section-map {
  width: 1087px;
  height: 352px;
  border-radius: 8px;
}

#contact-section .contact-section-map iframe {
  border-radius: 8px;
}

@media only screen and (max-width: 1151px) {
  #contact-section #carousel {
    width: 100%;
    height: 352px;
    border-radius: 8px;
  }

  #contact-section #carousel img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #contact-section .contact-section-map {
    width: auto;
    height: 352px;
    flex-grow: 1;
  }
}

@media only screen and (max-width: 750px) {
  #contact-section .contact-session {
    width: 100%;
  }

  #contact-section {
    padding: 0px 20px 20px 20px;
  }
}
