#game-video-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

#game-video-modal .game-video-modal-content {
  width: 100%;
  height: 100%;
  max-width: 1070px;
  max-height: 600px;
  position: relative;
}

#game-video-modal .game-video-modal-content .game-video {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #000;
}

#game-video-modal .game-video-modal-content .close-modal {
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 9999;
}

@media only screen and (max-width: 1151px) {
  #game-video-modal .game-video-modal-content {
    max-width: 700px;
    max-height: 392px;
  }
}

@media only screen and (max-width: 959px) {
  #game-video-modal .game-video-modal-content {
    max-width: 100%;
    max-height: 100%;
  }
}

@media only screen and (max-width: 479px) {
  #game-video-modal .game-video-modal-content {
    position: relative;
  }

  #game-video-modal .game-video-modal-content .game-video {
    transform: rotate(-90deg);
    height: 100vw;
    width: 100vh;
    position: absolute;
    top: calc((100vh - 100vw) / 2);
    left: calc((100vw - 100vh) / 2);
    z-index: 1;
  }

  #game-video-modal .game-video-modal-content .close-modal {
    position: absolute;
    top: 15px;
    left: 20px;
    z-index: 9999;
  }
}
