:root {
  --color-black-currant: #0e0020;
  --color-blue: #0050c0;
  --color-green: #00984f;
  --color-marine-900: #020b19;
  --color-marine-800: #131a25;
  --color-marine-700: #2a303a;
  --color-marine-600: #494f57;
  --color-marine-500: #666b72;
  --color-marine-400: #8c9095;
  --color-marine-300: #a8abaf;
  --color-marine-200: #c7c8cb;
  --color-marine-100: #e5e6e7;
  --color-white: #ffffff;
}
