.carousel {
  width: 100%;
  height: 100%;
  position: relative;
}

.carousel img {
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  transition: all 1s ease-in-out;
  z-index: 1;
  border-radius: 8px;
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.carousel img.active {
  opacity: 1;
}

.carousel .carousel-buttons {
  position: absolute;
  z-index: 2;
  top: 20px;
  right: 25px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.carousel .carousel-pages {
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 3;
}

.carousel .carousel-pages button {
  width: 12px;
  height: 12px;
  padding: 0;
  margin: 0;
  margin-right: 10px;
  background-color: rgba(255, 255, 255, 0.25);
}

.carousel .carousel-pages button:hover {
  background-color: rgba(255, 255, 255, 1);
}

.carousel .carousel-pages button.active {
  background-color: rgba(255, 255, 255, 1);
}

.carousel .carousel-title {
  z-index: 3;
  position: absolute;
  left: 0px;
  bottom: 0px;
  background-color: var(--color-marine-900);
  color: var(--color-white);
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  padding: 10px 15px;
  border-radius: 0px 8px 0px 0px;
}
