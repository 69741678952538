#pappers-grade-section {
  background-image: url("../img/pappers-grade-background.png");
  height: 600px;
  width: 100%;
  background-position: center top;
  position: relative;
  z-index: 1;
}

#pappers-grade-section .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(19, 26, 37, 0.9);
  mix-blend-mode: hard-light;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

#pappers-grade-section .content {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

#pappers-grade-section .content .buttons-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  margin-top: -20px;
}

#pappers-grade-section .content .pappers-grade-banner {
  max-width: 450px;
  height: auto;
}

@media (max-width: 479px) {
  #pappers-grade-section {
    max-height: 550px;
  }

  #pappers-grade-section .content {
    padding: 0px 20px;
  }

  #pappers-grade-section .content .pappers-grade-banner {
    max-width: 100%;
    width: 350px;
    height: auto;
  }

  #pappers-grade-section .content .buttons-section {
    row-gap: 30px;
    flex-wrap: wrap;
  }
}
