#footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 72px;
}

.footer-social-medias {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  flex-grow: 1;
  padding: 0px 20px;
}

.footer-social-medias p {
  text-transform: uppercase;
  font-family: 'Barlow';
  font-weight: 500;
  letter-spacing: 0.15em;
}

.footer-description {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  flex-grow: 1;
  padding: 0px 20px;
  padding-left: 60px;
}

.button-top-section {
  flex-grow: 1;
  padding: 0px 15px;
  display: flex;
  justify-content: right;
}

.button-to-top {
  color: #000 !important;
  background-image: url(../icons/up-icon.svg);
  padding-right: 35px;
  background-repeat: no-repeat;
  background-position: center right 10px;
  background-color: transparent;
}

.button-to-top:hover {
  background-color: var(--color-marine-200);
}

@media only screen and (max-width: 750px) {
  #footer {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px 0px;
  }

  .footer-description {
    width: 100%;
    order: 1;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    padding-top: 10px;
    text-align: center;
  }

  .footer-description img {
    width: 45px;
    height: auto;
  }

  .footer-description p {
    margin: 10px 0px;
    margin-bottom: 0px;
  }

  .footer-social-medias {
    order: 2;
  }

  .button-top-section {
    order: 3;
  }
}
