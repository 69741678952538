@import url('./root.css');

#our-pride-section {
  width: 100%;
  height: auto;
  padding: 25px 0px;
  background-color: var(--color-marine-900);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 88px;
}

#our-pride-section .our-pride-page-logo {
  width: 607px;
  height: auto;
}

#our-pride-section .our-pride-page-logo img {
  width: 100%;
  height: auto;
}

#our-pride-section .our-pride-page-content {
  max-width: 464px;
  padding-right: 20px;
}

#our-pride-section .our-pride-page-content h1 {
  color: var(--color-white);
  font-weight: 275;
  margin-top: 0;
}

#our-pride-section .our-pride-page-content p {
  color: var(--color-marine-200);
}

.scroll-down-button {
  color: var(--color-white);
  padding-left: 40px;
  background-image: url('../icons/chevron-double-down.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: center left 10px;
  margin-top: 66px;
}

#mask-button-scroll {
  display: none;
}

@media (max-width: 1151px) {
  #our-pride-section {
    column-gap: 70px;
  }

  #our-pride-section .our-pride-page-logo {
    width: 380px;
  }

  #our-pride-section .our-pride-page-content {
    max-width: 500px;
  }

  #our-pride-section .our-pride-page-content .scroll-down-button {
    margin-top: 16px;
  }

  #our-pride-section .our-pride-page-content h1 {
    font-size: 40px;
    margin-bottom: 5px;
    margin-top: 0px;
  }
}

@media (max-width: 959px) {
  #our-pride-section {
    column-gap: 48px;
  }

  #our-pride-section .our-pride-page-logo {
    width: 308px;
    background-size: 308px 296px;
  }

  #our-pride-section .our-pride-page-content {
    max-width: 328px;
    padding-right: 0px;
  }

  #our-pride-section .our-pride-page-content .scroll-down-button {
    margin-top: 16px;
  }

  #our-pride-section .our-pride-page-content h1 {
    font-size: 40px;
    margin-bottom: 5px;
    margin-top: 0px;
  }
}

@media (max-width: 659px) {
  #our-pride-section {
    flex-direction: column;
    row-gap: 30px;
    padding: 30px 40px;
    width: 100%;
  }

  #our-pride-section .our-pride-page-logo {
    width: 100%;
    padding-right: 11%;
  }

  #our-pride-section .our-pride-page-content {
    max-width: 100%;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #our-pride-section .our-pride-page-content .scroll-down-button {
    display: none;
  }

  #mask-button-scroll {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(to top, var(--color-marine-900), transparent);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 25px;
    z-index: 99;
    transition: all ease-out 0.5s;
  }

  #mask-button-scroll.active {
    visibility: visible;
    opacity: 1;
  }

  #mask-button-scroll.inactive {
    visibility: hidden;
    opacity: 0;
  }
}

@media (max-width: 450px) {
  #our-pride-section {
    padding: 30px 20px;
  }

  #our-pride-section .our-pride-page-content {
    max-width: 100%;
    padding: 0px 0px;
  }
}
