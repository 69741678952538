@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlow-ExtraLight.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlow-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Barlow";
  src: url("../fonts/Barlow-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/Ubuntu-Light.ttf");
  font-weight: 300;
}

h1 {
  font-size: 48px;
  line-height: 56px;
  font-family: "Barlow";
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

h2 {
  font-size: 40px;
  line-height: 48px;
  font-family: "Barlow";
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

h3 {
  font-size: 32px;
  line-height: 40px;
  font-family: "Barlow";
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

p {
  font-family: "Ubuntu";
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  font-family: "Ubuntu";
  overflow-x: hidden !important;
}

.logo {
  width: 24px !important;
  height: 24px !important;
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 0.5s ease-out;
  border-radius: 0;
  background-color: transparent !important;
  padding: 0;
}

.instagram-logo {
  background-image: url("../icons/instagram-icon-white.svg");
}

.instagram-logo.black {
  background-image: url("../icons/instagram-icon-black.svg");
}

.instagram-logo:hover {
  background-image: url("../icons/instagram-icon-colored.svg");
}

.linkedin-logo {
  background-image: url("../icons/linkedin-icon-white.svg");
}

.linkedin-logo.black {
  background-image: url("../icons/linkedin-icon-black.svg");
}

.linkedin-logo:hover {
  background-image: url("../icons/linkedin-icon-colored.svg");
}

.email-icon {
  background-image: url("../icons/email-icon.svg");
  width: 20px;
  height: 16px;
  background-repeat: no-repeat;
}

.phone-number-icon {
  background-image: url("../icons/phone-number-icon.svg");
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
}

.address-icon {
  background-image: url("../icons/address-icon.svg");
  width: 26px;
  height: 20px;
  background-repeat: no-repeat;
}

.copy-icon {
  background-image: url("../icons/copy.svg");
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: 100%;
}

.language-section {
  display: flex;
  justify-content: flex-end;
}

.language-change-button {
  display: flex;
  flex-direction: row;
  padding: 0px 20px;
}

.language-change-button button:nth-of-type(1) {
  border-radius: 20px 0px 0px 20px;
  padding: 8px 10px 8px 15px;
}

.language-change-button button:nth-of-type(2) {
  border-radius: 0px 20px 20px 0px;
  padding: 8px 15px 8px 10px;
}

.actions-buttons-next-prev {
  width: 40px;
  height: 40px;
  background-color: var(--color-marine-900);
  transition: all 0.5s ease-out;
  background-repeat: no-repeat;
  background-position: center center;
}

.actions-buttons-next-prev:hover {
  background-color: var(--color-blue);
}

.actions-buttons-next-prev.previous-icon {
  background-image: url(../icons/previous.svg);
  background-position: center right 16px;
}

.actions-buttons-next-prev.next-icon {
  background-image: url(../icons/next.svg);
  background-position: center right 14px;
}

.actions-buttons-next-prev.close-icon {
  background-image: url(../icons/close.svg);
  background-position: center right 10px;
}

.buttons-section .play-video-button {
  padding: 15px 20px 15px 10px;
  padding-left: 50px;
  background-image: url("../icons/play-circle.svg");
  background-repeat: no-repeat;
  background-size: 32px 32px;
  background-position: center left 10px;
}

.buttons-section .play-video-button:hover {
  background-color: var(--color-blue);
  color: var(--color-white);
  background-image: url("../icons/play-circle-white.svg");
}

@media only screen and (max-width: 480px) {
  .email-icon {
    display: none;
  }

  .phone-number-icon {
    display: none;
  }

  .address-icon {
    display: none;
  }

  .wrapper-play-video-button {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  .buttons-section .play-video-button {
    padding: 15px 20px 15px 10px;
    padding-left: 50px;
    background-image: url("../icons/play-circle.svg");
    background-repeat: no-repeat;
    background-size: 32px 32px;
    background-position: center left 10px;
  }
}

@import url("./general.css");
@import url("./navbar.css");
@import url("./navBarFullScreen.css");
@import url("./ourPrideSection.css");
@import url("./darkCrownSection.css");
@import url("./pappersGradeSection.css");
@import url("./gameVideoModal.css");
@import url("./screenshotModal.css");
@import url("./projectsSection.css");
@import url("./projectsModal.css");
@import url("./essenceSection.css");
@import url("./contactSection.css");
@import url("./footer.css");
