#essence-section h1 {
  text-align: center;
}

#essence-section .top-wave {
  height: 96px;
  width: 100%;
  background-color: var(--color-marine-900);
  margin-top: -1px;
  clip-path: polygon(
    0% 0%,
    0% 82.5%,
    1.69492% 84.3293%,
    3.38983% 86.1385%,
    5.08475% 87.9078%,
    6.77966% 89.6179%,
    8.47458% 91.25%,
    10.1695% 92.7862%,
    11.8644% 94.2098%,
    13.5593% 95.505%,
    15.2542% 96.6578%,
    16.9492% 97.6555%,
    18.6441% 98.487%,
    20.339% 99.1435%,
    22.0339% 99.6176%,
    23.7288% 99.9041%,
    25.4237% 100%,
    27.1186% 99.9041%,
    28.8136% 99.6176%,
    30.5085% 99.1435%,
    32.2034% 98.487%,
    33.8983% 97.6555%,
    35.5932% 96.6578%,
    37.2881% 95.505%,
    38.9831% 94.2098%,
    40.678% 92.7862%,
    42.3729% 91.25%,
    44.0678% 89.6179%,
    45.7627% 87.9078%,
    47.4576% 86.1385%,
    49.1525% 84.3293%,
    50.8475% 82.5%,
    52.5424% 80.6708%,
    54.2373% 78.8616%,
    55.9322% 77.0922%,
    57.6271% 75.3821%,
    59.322% 73.75%,
    61.017% 72.2138%,
    62.7119% 70.7902%,
    64.4068% 69.495%,
    66.1017% 68.3422%,
    67.7966% 67.3446%,
    69.4915% 66.513%,
    71.1864% 65.8565%,
    72.8814% 65.3824%,
    74.5763% 65.0959%,
    76.2712% 65%,
    77.9661% 65.0959%,
    79.661% 65.3824%,
    81.3559% 65.8565%,
    83.0509% 66.513%,
    84.7458% 67.3446%,
    86.4407% 68.3422%,
    88.1356% 69.495%,
    89.8305% 70.7902%,
    91.5254% 72.2138%,
    93.2203% 73.75%,
    94.9153% 75.3821%,
    96.6102% 77.0922%,
    98.3051% 78.8616%,
    100% 80.6708%,
    100% 0%
  );
  shape-outside: polygon(
    0% 0%,
    0% 82.5%,
    1.69492% 84.3293%,
    3.38983% 86.1385%,
    5.08475% 87.9078%,
    6.77966% 89.6179%,
    8.47458% 91.25%,
    10.1695% 92.7862%,
    11.8644% 94.2098%,
    13.5593% 95.505%,
    15.2542% 96.6578%,
    16.9492% 97.6555%,
    18.6441% 98.487%,
    20.339% 99.1435%,
    22.0339% 99.6176%,
    23.7288% 99.9041%,
    25.4237% 100%,
    27.1186% 99.9041%,
    28.8136% 99.6176%,
    30.5085% 99.1435%,
    32.2034% 98.487%,
    33.8983% 97.6555%,
    35.5932% 96.6578%,
    37.2881% 95.505%,
    38.9831% 94.2098%,
    40.678% 92.7862%,
    42.3729% 91.25%,
    44.0678% 89.6179%,
    45.7627% 87.9078%,
    47.4576% 86.1385%,
    49.1525% 84.3293%,
    50.8475% 82.5%,
    52.5424% 80.6708%,
    54.2373% 78.8616%,
    55.9322% 77.0922%,
    57.6271% 75.3821%,
    59.322% 73.75%,
    61.017% 72.2138%,
    62.7119% 70.7902%,
    64.4068% 69.495%,
    66.1017% 68.3422%,
    67.7966% 67.3446%,
    69.4915% 66.513%,
    71.1864% 65.8565%,
    72.8814% 65.3824%,
    74.5763% 65.0959%,
    76.2712% 65%,
    77.9661% 65.0959%,
    79.661% 65.3824%,
    81.3559% 65.8565%,
    83.0509% 66.513%,
    84.7458% 67.3446%,
    86.4407% 68.3422%,
    88.1356% 69.495%,
    89.8305% 70.7902%,
    91.5254% 72.2138%,
    93.2203% 73.75%,
    94.9153% 75.3821%,
    96.6102% 77.0922%,
    98.3051% 78.8616%,
    100% 80.6708%,
    100% 0%
  );
}

#essence-section .inverse {
  transform: scaleY(-1);
  margin-bottom: -1px;
}

#essence-section .essence-section-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 40px;
  padding-bottom: 50px;
  padding-top: 20px;
  width: 100%;
}

#essence-section .essence-section-content .essence-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  row-gap: 40px;
  column-gap: 25px;
  padding-top: 20px;
}

#essence-section .essence-section-content .essence-items .essence-item {
  flex-basis: 28%;
  max-width: 28%;
  min-width: 284px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#essence-section .essence-section-content .essence-items .essence-item:nth-of-type(3n + 2) {
  padding-top: 35px;
}

.essence-item-label {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: var(--color-marine-900);
  margin-top: 30px;
}

.essence-item-description {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--color-marine-900);
  max-width: 284px;
  margin-top: 10px;
}

.essence-item .essence-icon {
  transition: all 0.5s ease-out;
  background-repeat: no-repeat;
  background-position: center;
  width: 134px !important;
  height: 126px !important;
}

.essence-item .essence-icon.involvement {
  width: 125px;
  height: 116px;
  background-image: url('../icons/essence/involvement-default.png');
}

.essence-item .essence-icon.involvement:hover {
  background-image: url('../icons/essence/involvement-hover.png');
}

.essence-item .essence-icon.criativity {
  width: 114px;
  height: 124px;
  background-image: url('../icons/essence/criativity-default.png');
}

.essence-item .essence-icon.criativity:hover {
  background-image: url('../icons/essence/criativity-hover.png');
}

.essence-item .essence-icon.collaboration {
  width: 125px;
  height: 115px;
  background-image: url('../icons/essence/collaboration-default.png');
}

.essence-item .essence-icon.collaboration:hover {
  background-image: url('../icons/essence/collaboration-hover.png');
}

.essence-item .essence-icon.experimentation {
  width: 134px;
  height: 116px;
  background-image: url('../icons/essence/experimentation-default.png');
}

.essence-item .essence-icon.experimentation:hover {
  background-image: url('../icons/essence/experimentation-hover.png');
}

.essence-item .essence-icon.agility {
  width: 127px;
  height: 124px;
  background-image: url('../icons/essence/agility-default.png');
}

.essence-item .essence-icon.agility:hover {
  background-image: url('../icons/essence/agility-hover.png');
}

.essence-item .essence-icon.empathy {
  width: 124px;
  height: 126px;
  background-image: url('../icons/essence/empathy-default.png');
}

.essence-item .essence-icon.empathy:hover {
  background-image: url('../icons/essence/empathy-hover.png');
}

@media (max-width: 958px) {
  #essence-section .essence-section-content .essence-items {
    column-gap: 60px;
  }
  #essence-section .essence-section-content .essence-items .essence-item:nth-of-type(3n + 2) {
    padding-top: 0px;
  }
}

@media screen and (max-width: 650px) {
  .essence-item.active .essence-icon.involvement {
    background-image: url('../icons/essence/involvement-hover.png');
  }

  .essence-item.active .essence-icon.criativity {
    background-image: url('../icons/essence/criativity-hover.png');
  }

  .essence-item.active .essence-icon.collaboration {
    background-image: url('../icons/essence/collaboration-hover.png');
  }

  .essence-item.active .essence-icon.experimentation {
    background-image: url('../icons/essence/experimentation-hover.png');
  }

  .essence-item.active .essence-icon.agility {
    background-image: url('../icons/essence/agility-hover.png');
  }
  .essence-item.active .essence-icon.empathy {
    background-image: url('../icons/essence/empathy-hover.png');
  }

  #essence-section .top-wave {
    height: 50px;
  }
}
